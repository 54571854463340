import React from 'react';

import HeaderImage from '@assets/images/app.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

import './textBlock.scss';

type TTextBlock = {
  title: string,
  text: string,
  btnText: string,
  onButtonClick: () => void,
  info: string,
};

const TextBlock = ({
  title,
  text,
  btnText,
  onButtonClick,
  info,
}: TTextBlock) => (
  <section data-theme="white">
    <div className="container container--sm margin-top--sm">
      <div className="text-block" style={{ margin: '0 auto' }}>
        <div className="text-block__image-wrapper">
          <img src={HeaderImage} alt="" />
        </div>

        <div className="margin-bottom--sm margin-top--xs text--center">
          <h2 className="margin-bottom--xxs">{title}</h2>
          <p className="text-block__header-text">{text}</p>
        </div>

        <div className="flex flex--center margin-bottom--sm">
          <a
            href="#"
            onClick={event => {
              event.preventDefault();
              onButtonClick();
            }}
            type="button"
            role="button"
            className="btn btn--primary btn--lg"
          >
            {btnText}
            <FontAwesomeIcon
              icon={faArrowRight}
              size="sm"
              style={{
                color: 'white',
                position: 'relative',
                top: 2,
                paddingLeft: 8,
                fontWeight: 600,
              }}
            />
          </a>
        </div>

        <div className="text-block__info">
          <p className="text--center text--xs">
            {info}
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default TextBlock;
