/* eslint-disable max-len */
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Layout from '@components/common/Layout';
import Seo from '@components/common/Seo';
import IconCardContainer from '@components/iconCard/IconCardContainer';
import IconCard from '@components/iconCard/IconCard';
import TextBlock from '@components/textBlock/TextBlock';

import ImageHip from '@assets/images/variant-hip.svg';
import ImageKnee from '@assets/images/variant-knee.svg';
import ImageShoulder from '@assets/images/variant-shoulder.svg';
import ImageNeck from '@assets/images/variant-neck.svg';
import ImageBack from '@assets/images/variant-back.svg';
import ImageFallPrevention from '@assets/images/variant-fall-prevention.svg';
import ImageOsteoporosis from '@assets/images/variant-osteoporosis.svg';

const IndexPage = () => {
  const [showJoints, setShowJoints] = useState(false);

  return (
    <Layout language="sv" segmentId={process.env.GATSBY_SEGMENT_SCREENING_2_PRODUCTION_SE as string}>
      <Seo
        title="Screening"
        description="Screening"
        lang="sv"
      />

      {!showJoints && (
        <TextBlock
          title="Artros- och ledtestet"
          text="Svara på ett par enkla frågor och få en bedömning av din risk för att ha eller utveckla artros och långvarig ledsmärta."
          btnText="Starta testet"
          onButtonClick={() => setShowJoints(true)}
          info="Testet är framtaget av våra medicinska experter utefter vetenskapliga studier, riskfaktorer och symtom. Genom att tidigt identifiera din risknivå kan vi rekommendera rätt behandling för att minska ledsmärta och öka livskvalitén."
        />
      )}

      <CSSTransition
        classNames="fade--500"
        in={showJoints}
        mountOnEnter
        timeout={300}
        exit={false}
      >
        <IconCardContainer title="Vilket test vill du göra?">
          <IconCard
            title="Axel"
            imageSrc={ImageShoulder}
            url="/se/sv/axel/"
          />
          <IconCard
            title="Rygg"
            imageSrc={ImageBack}
            url="/se/sv/rygg/"
          />
          <IconCard
            title="Nacke"
            imageSrc={ImageNeck}
            url="/se/sv/nacke/"
          />
          <IconCard
            title="Höft"
            imageSrc={ImageHip}
            url="/se/sv/hoft/"
          />
          <IconCard
            title="Knä"
            imageSrc={ImageKnee}
            url="/se/sv/kna/"
          />
          <IconCard
            title="Fallprevention"
            imageSrc={ImageFallPrevention}
            url="/se/sv/fallprevention/"
          />
          <IconCard
            title="Osteoporos"
            imageSrc={ImageOsteoporosis}
            url="/se/sv/osteoporos/"
          />
          <IconCard
            title="Annan led"
            url="/se/sv/annat/"
          />
        </IconCardContainer>
      </CSSTransition>
    </Layout>
  );
};

export default IndexPage;
